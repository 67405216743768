import React from "react"
import {repData} from '../data/RepData.js'

export default function RepPane({paneTitle, paneData}) {

    const content = paneData? paneData[paneTitle] : repData[paneTitle]

    function computeHeight(content) {
        var height = 0, maxItem = 0
        content.forEach(item => {
            var itemHeight = 60 + item.titles.length *18
            height += itemHeight
            maxItem = Math.max(maxItem, itemHeight)
        })
        return Math.max((height / 2) + 100 + maxItem, 1000)
    }
    
    var boxHeight = computeHeight(content) + "px"

    function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    return (
        <div>
        <div className="body-title-style">
            <h1 className="rep-h1">{capitalize(paneTitle)}</h1>
        </div>
        <div className="body-style2" style={{height: boxHeight}}>
            {content.map(compGroup => {
                return (
                    <div className="width-48">
                        <h4>{compGroup.composer}</h4>
                            {compGroup.titles.map(title => <p className="piece-title">{title}</p>)}
                        </div>
                    )
                })}
        </div>
        </div>
    )  
}