import React from "react"
import {RecData} from '../data/RecData.js'
import Audio from "./Audio"

export default function RecordingsPane() {

    const audioDivs = RecData.map((audio, idx) => {
        return (
            <div className="recordings-div">
            <Audio name={"audio"+idx} source={audio.img} tracks={audio.tracks}/>
             <div>
                 {audio.paragraphs && audio.paragraphs.map(p => <p className="audio--paragraph">{p}</p>)}
             </div>
            </div>
        )
    })
    return (
        <div className="body-title-style">
            <div className="recordings-div">
                {audioDivs}
            </div>
        </div>
    )  
}