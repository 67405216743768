import React, { useState } from "react";
// import axios from "axios";
// const cors = require("cors");

// function jason2http(params) {
//   return Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
// }

export default function ContactForm () {
  // const [status, setStatus] = useState("Submit");
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setStatus("Sending...");
  //   const { name, email, message } = e.target.elements;
  //   let details = {
  //     name: name.value,
  //     email: email.value,
  //     message: message.value,
  //     my_email: "jvbwyo@gmail.com",
  //     thankyou_url: "http://www.rubiasantospiano.com"
  //   };
    // let response = await fetch("https://www1.ipage.com/scripts/formemail.html", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json;charset=utf-8",
    //   },
    //   body: JSON.stringify(details),
    // });
    // const instance = axios.create()
    // const data = new FormData()
    // data.append("name", name)
    // data.append("email", email)
    // data.append("message",message)
    // const baseURL = "https://www1.ipage.com/scripts/formemail.html"
    // try {
      // let response = await axios
      // .post(baseURL, {
      //   body: jason2http(details)
      // },{ headers: { 'Access-Control-Allow-Origin': 'true' }}
      // )
  //     let response = await instance
  //     .post(baseURL,data)
  //     console.log("Response: " + response)
  // } catch (err) {
  //     if (err.request) {
  //       console.log(err.request)
  //     } else if (err.response) {
  //       console.log(err.response)
  //     } else console.log('Error', err.message)
  // }
    // setStatus("Submit");
    // let result = await response.json();
    // alert(result.status);
  // };
  return (
    <div className="contact-intro">
      <p>Welcome to my website!</p>
       <p>Please use this means of contact. If you wish to reach me for more information, for my freelancing work as a pianist/collaborator, and if you are interested in acquiring Brazilian music scores.</p>
       <p>Thanks for your interest! I will get back to you as soon as possible.</p>
    {/* <form onSubmit={handleSubmit}> */}
    <form method= "post" action="https://www1.ipage.com/scripts/formemail.html">
    <input type="hidden" name="my_email" value="rssantos13@gmail.com"/>
    <input type="hidden" name="thankyou_url" value="http://www.rubiasantospiano.com"/>  
      <table className="contact-pane" align="center">
        <tbody>
          <tr>
              <td><label className="contact-btn" htmlFor="name">Name:</label></td>
              <td><input name="name" className="contact-input" type="text" id="name" required /></td>
          </tr>
          <tr>
              <td><label className="contact-btn" htmlFor="email">Email:</label></td>
              <td><input name="email" className="contact-input" type="email" id="email" required /></td>
          </tr>
          <tr>
              <td valign="top" style={{paddingTop: "30px"}}><label className="contact-btn" htmlFor="message">Message:</label></td>
              <td><textarea name="message" rows={20} cols={50} className="contact-input" id="message" required /></td>
          </tr>
          <tr>
            <td colSpan={2} align="center"><button className="contact-submit" type="submit">Send</button></td>
          </tr>
        </tbody>
      </table>
    </form>
    </div>
  );
};
