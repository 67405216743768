import React, {useEffect} from 'react'

export default function Tab({firstRef, text, selFn, setTabsPaneFn}) {

    useEffect(() => {
        firstRef && (firstRef.current.style.backgroundColor = "#b38cee")
    },[])
    if (firstRef)
        return (
            <div ref={firstRef} className="tab" onClick={(e) => {
                e.currentTarget.style.backgroundColor = "#b38cee"
                setTabsPaneFn({tab: e.target, fn: selFn})
            }}>
                {text}
            </div>)
        return (
            <div className="tab" onClick={(e) => {
                e.currentTarget.style.backgroundColor = "#b38cee"
                setTabsPaneFn({tab: e.target, fn: selFn})
            }}>
            {text}
        </div>
        )
}