import React, {useContext} from "react";
import { TwddContext } from "./TabWDropdown";

export default function DropdownItem({children, goToMenu, icon, selFn, repPane, setTabsPaneFn}) {
    
    const {setActiveMenu, setMenuOpen} = useContext(TwddContext)
    
    return (
    selFn ? (
        <button
            className="menu-item"
            onClick={() => {
                setMenuOpen(false)
                setTabsPaneFn({fn: selFn})
            }}
        >
            {children}
        </button>)
        :
        <button
            className="menu-item"
            onClick={() => goToMenu && setActiveMenu(goToMenu)}
        >
            {icon && <span className="icon-button">{icon}</span>} {children}
        </button>
    );
  }