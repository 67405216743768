import React from 'react'
import './App.css';
import Header from './components/Header.js'
import Body from './components/Body.js'
import Footer from './components/Footer.js'
import AboutPane from './components/AboutPane'

export default function App() { 

  const [tabsPaneFn, setTPFn] = React.useState({fn:AboutPane})
  function setTabsPaneFn(obj) {
    tabsPaneFn.closeFn && tabsPaneFn.closeFn(false)
    tabsPaneFn.tab && (tabsPaneFn.tab.style.backgroundColor = "#4F6690")
    setTPFn(obj)
  }
  
  return ( 
    <div className="App">
      <div className="App-inner-body">
        <Header setTabsPaneFn={setTabsPaneFn} />
        <Body tabsPaneFn={tabsPaneFn}/>
        <Footer />
      </div>
    </div> 
  )
}
