import React, {useEffect, useRef} from "react"
import Audio from "./Audio"
import Tabs from "./Tabs"
import Tab from "./Tab"

function duo() {

    return (
        <div style={{marginTop: "20px"}}>
            <div>
            <Audio name="player10"
               source={require("../images/RubiaMelanie.png")}
               tracks={[{source: require("../audiovideo/Embolada.mp3"), name: "Pires de Campos - Embolada"}]}
               />
            </div>

            <p>Duo Braziliana researches and performs Brazilian and Latin American art song. Pianist Rúbia Santos and mezzo-soprano Melani Ohm met at Arizona State University. Since then, both artist have passionately 
            shared their interest for the culture of South America, in particular its weatlh of music and literature. In 2002 Santos and Ohm began collaborating and reseraching works by Brazilian composers of the past and present. 
            The Duo’s international debut was in Genoa, Italy, during the 2006 Mediterranean Studies Association Conference.  Santos’ edition of <em>Eight Art Songs</em> by contemporary Brazilian composer Edmundo Villani-Côrtes was released in 2012.</p>
        </div>
    )
}

function diction() {
    return (
        <div>
            <p>In 2003 Brazilian singers and researchers started revisiting and discussing about guidelines for singing texts in Brazilian Portuguese language. From that first encounter came a strong interestet and 
            profound scholarly work to publish norms of pronunciation and transcription. The Norms for Lyric Diciton of Brazilian Portuguese were revised and finalized in 2007.  Below, you can see a short section of 
            the normative table. The complete source is published  on the Jounal of Singing, Volume 65, no. 2 by Martha Herr, Adriana Kayama, and Wladimir Mattos.</p>

            <p>"Because of the strong regionalism, as evidenced by the many accents in the country, diction used by classical singers to perform songs in Portuguese is not uniform. Most sounds in Brazilian Portuguese are 
            closely related to their Italian counterparts, so a good knowledge of Italian diction could help beginners in the first approach" (Luciano Simões).</p>
            <div className="center-iframe">
                <p><img src={require("../images/BPRef.jpg")} width="632" height="670"/></p>
            </div>
        </div>
    )
}

function recsPubs() {
    return (
        <div>
            <p style={{fontWeight: "bold"}}>EIGHT ART SONGS by Edmundo Villani-Côrtes</p>
            <p>Edited by Rúbia Santos<br/>
            Ponteio Publishing Inc. 2012</p>
            <p> This edition, partially funded by the University of Wyoming, includes the transcriptions for solo piano of seven original songs and of O passarinho da Praça da Matriz, a work originally composed fo choir and symphonic band.</p>
            <Audio name="player11"
               source={require("../images/PianoSongs.jpg")}
               tracks={[{source: require("../audiovideo/Baile.mp3"), name: "Baile imaginário"},
                        {source: require("../audiovideo/Valsinha de Roda_01.mp3"), name: "Valsinha de roda"},
                        {source: require("../audiovideo/Passarinho_Oct8.mp3"), name: "O passarinho da Praça da Matriz"},
                        {source: require("../audiovideo/FonteEterna_Oct8.mp3"), name: "A fonte eterna "},
                        {source: require("../audiovideo/Balada.mp3"), name: "Balada dos 15 minutos"},
                        {source: require("../audiovideo/Carolina_Oct11.mp3"), name: "Canção de Carolina"},
                        {source: require("../audiovideo/Sem Nome_Sep16.mp3"), name: "Sem nome"}]}
               />
               <div className="center-iframe" style={{marginTop: "20px"}}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/k5LsMunqkxY" 
                    title="YouTube video player" frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                </iframe>
               </div>
               <div className="center-iframe" style={{marginTop: "20px"}}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/TDjYy1Hy75Y" 
                    title="YouTube video player" frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                </iframe>
                </div>
            <div className="center-iframe">
                <p><img src={require("../images/CoverVillani.jpeg")} width="632" height="670"/></p>
            </div>
        </div>
    )
}

export default function BrasilTab() {

    const [tabsFn, setTabsFn] = React.useState({fn: duo})
    const firstRef = useRef()

    function setTabsPaneFn(obj) {
        tabsFn.tab && (tabsFn.tab.style.backgroundColor = "#4F6690")
        setTabsFn(obj)
    }

    useEffect(() => {
        firstRef.current.style.backgroundColor = "#b38cee"
        setTabsPaneFn({tab: firstRef.current, fn: duo})
    },[])

    return (
    <div className="body-title-style">
        <div className="style4"> 
        <p>International scholarship involving Brazilian culture and music has attracted the attention of a large audience.  Contemporary Brazilian composers are commissioned to write works for small, 
		as well as, large ensembles, including theatrical works, such as operas. The purpose of such commissions is to aim, in particular, for performances and recordings. The literature is imprinted with 
		lyric and percussive sonorities, both qualities favored among native composers.</p>

        <p>A great number of the repertoire includes music for piano and guitar, the most popular instruments in the country. The Brazilian art song genre has increased in number because the language has unique 
	    musical inflection. Many poets and composers have worked together to create intimate works, regardless of whether the music or the text was written first.</p>

        <p>Many Brazilian Music Societies were established in the 20th century, to bring awareness about music styles, opening venues for composers and interpreters who were interested in popularizing the 
        literature. As a pianist, I have found it extremely rewarding to promote Brazilian music and I am passionate about divulging the extensive repertoire that includes piano in chamber music and vocal works in 
        addition to solo pieces.</p>
       
       <Tabs>
        <Tab firstRef={firstRef} text="Duo Braziliana" selFn={duo} setTabsPaneFn={setTabsPaneFn}/>
        <Tab text="Brazilian Portuguese Lyric Diction" selFn={diction} setTabsPaneFn={setTabsPaneFn}/>
        <Tab text="Recordings and Publications" selFn={recsPubs} setTabsPaneFn={setTabsPaneFn}/>
       </Tabs>
       {tabsFn && tabsFn.fn()}
        </div>
    </div>
    )  
}