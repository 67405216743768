import React, {useRef, useEffect} from 'react'
import Tabs from './Tabs'
import Tab from './Tab'
import AboutPane from './AboutPane'
import BrasilPane from './BrasilPane'
import DuoAlmaPane from './DuoAlmaPane'
import rubiaImg from "../images/RUBIA3.jpg"
import duo from "../images/Duo.jpg"
import inMovement from "../images/InMovement.jpg"
import hands from "../images/Hands.jpg"
import rubiaBW from "../images/RubiaBW.jpg"
import rubiaBW1 from "../images/rubiaBW1.jpg"
import rubiaColor4 from "../images/rubiaColor4.jpg"
import rubiaColor1 from "../images/rubiaColor1.png"
import RecordingsPane from './RecordingsPane'
import ContactPane from './ContactPane'
import RepPane from './RepPane'
import TabWDropDown from './TabWDropdown'
import Menu from './Menu'
import DropdownItem from './DropdownItem'
import Slider from './Slider'
import { ReactComponent as ArrowBackIcon } from "../icons/back-arrow-svgrepo-com.svg";

const images = [
    rubiaImg, duo, inMovement, hands, rubiaBW, rubiaBW1, rubiaColor1, rubiaColor4
]

export default function Header({setTabsPaneFn, paneTitle}) {

    const firstTabRef = useRef();

    useEffect(() => {
        setTabsPaneFn({tab: firstTabRef.current, fn: AboutPane})
    }, [])

    return (
        <div>
            <Tabs>
                <Tab firstRef={firstTabRef} text="Biography" selFn={AboutPane} setTabsPaneFn={setTabsPaneFn}/>
                <Tab text="Latin America" selFn={BrasilPane} setTabsPaneFn={setTabsPaneFn}/>
                <Tab text="Duo Alma" selFn={DuoAlmaPane} setTabsPaneFn={setTabsPaneFn}/>
                <TabWDropDown text="Repertoire" defaultMenu="main" setTabsPaneFn={setTabsPaneFn}>
                    <Menu name="main">
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="Art Songs"/>}
                            setTabsPaneFn={setTabsPaneFn}>
                            Art Songs
                        </DropdownItem>
                        <DropdownItem goToMenu="brass">
                            Brass
                        </DropdownItem>
                        <DropdownItem goToMenu="winds">
                            Winds
                        </DropdownItem>
                        <DropdownItem goToMenu="strings">
                            Strings
                        </DropdownItem>
                        <DropdownItem goToMenu="ensemble">
                            Ensembles
                        </DropdownItem>
                        <DropdownItem goToMenu="opera">
                            Opera
                        </DropdownItem>
                    </Menu>
                    <Menu name="brass" menuClass="menu-secondary">
                        <DropdownItem icon={<ArrowBackIcon />} goToMenu="main">
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="trumpet"/>}
                            setTabsPaneFn={setTabsPaneFn}>
                            Trumpet
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="trombone"/>}
                            setTabsPaneFn={setTabsPaneFn}>
                            Trombone
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="tuba"/>}
                            setTabsPaneFn={setTabsPaneFn}>
                            Tuba
                        </DropdownItem>
                    </Menu>
                    <Menu name="winds" menuClass="menu-secondary">
                        <DropdownItem icon={<ArrowBackIcon />} goToMenu="main"/>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="saxophone"/>}
                            setTabsPaneFn={setTabsPaneFn}>
                            Saxophone
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="flute"/>} 
                            setTabsPaneFn={setTabsPaneFn}>
                            Flute
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="clarinet"/>}
                            setTabsPaneFn={setTabsPaneFn}>
                            Clarinet
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="oboe"/>} 
                            setTabsPaneFn={setTabsPaneFn}>
                            Oboe
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="bassoon"/>} 
                            setTabsPaneFn={setTabsPaneFn}>
                            Bassoon
                        </DropdownItem>
                    </Menu>
                    <Menu name="strings" menuClass="menu-secondary">
                        <DropdownItem icon={<ArrowBackIcon />} goToMenu="main"/>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="violin"/>} 
                            setTabsPaneFn={setTabsPaneFn}>
                            Violin
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="viola"/>}
                            setTabsPaneFn={setTabsPaneFn}>
                            Viola
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="cello"/>} 
                            setTabsPaneFn={setTabsPaneFn}>
                            Cello
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="bass"/>} 
                            setTabsPaneFn={setTabsPaneFn}>
                            Bass
                        </DropdownItem>
                    </Menu>
                    <Menu name="ensemble" menuClass="menu-secondary">
                        <DropdownItem icon={<ArrowBackIcon />} goToMenu="main"/>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="trios"/>} 
                            setTabsPaneFn={setTabsPaneFn}>
                            Trios
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="quartet"/>}
                            setTabsPaneFn={setTabsPaneFn}>
                            Quartet
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="quintet"/>} 
                            setTabsPaneFn={setTabsPaneFn}>
                            Quintet
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="sextet"/>} 
                            setTabsPaneFn={setTabsPaneFn}>
                            Sextet
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="large ensembles"/>} 
                            setTabsPaneFn={setTabsPaneFn}>
                            Large Ensembles
                        </DropdownItem>
                    </Menu>
                    <Menu name="opera" menuClass="menu-secondary">
                        <DropdownItem icon={<ArrowBackIcon />} goToMenu="main"/>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="complete operas"/>} 
                            setTabsPaneFn={setTabsPaneFn}>
                            Complete Operas
                        </DropdownItem>
                        <DropdownItem 
                            selFn={() => <RepPane paneTitle="arias"/>}
                            setTabsPaneFn={setTabsPaneFn}>
                            Arias
                        </DropdownItem>
                    </Menu>
                </TabWDropDown>
                <Tab text="Recordings" selFn={RecordingsPane} setTabsPaneFn={setTabsPaneFn}/>
                {/* <Tab text="Feldenkrais" selFn={FeldenPane} setTabsPaneFn={setTabsPaneFn}/> */}
                <Tab text="Contact" selFn={ContactPane} setTabsPaneFn={setTabsPaneFn}/>
            </Tabs>
            <div className="App-inner-body">
                <div className="hdrStuff">
                    <div className="width300">
                        <ul className="no-bullets">
                            <li className="style7">R&uacute;bia <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Santos</li>
                            <li className="style3">“Rúbia Santos is a performer who leaves the audience stunned by her provocative pianism as well as her enthusiastic temperament” (Frankfurter Algemeine Journal, Germany)</li>
                        </ul>
                    </div>
                    <Slider slides={images} namePrefix="slide"/>

                </div>
            </div>
        </div>
    )
}