import React, { useState, useEffect, useRef } from 'react'
import Slide from './Slide'

export default function Slider({slides, namePrefix}) {

    const numSlides = slides.length
    const [activeSlide, setActiveSlide] = useState(() =>"slide0")
    const timeoutRef = useRef(null);

    function resetTimeout() {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  
    useEffect(() => {
      resetTimeout();
      timeoutRef.current = setTimeout(nextSlide,10000);
      return () => {
        resetTimeout();
      };
    }, [activeSlide]);

    // console.log("Render Slider: " + activeSlide)

    function nextSlide() {
        setActiveSlide(prev => {
            const nextNum = (parseInt(prev.substring(namePrefix.length)) +1)
            return (namePrefix + (nextNum < numSlides ? nextNum : 0))
        })
    }

    const slideDivs = slides.map((s,idx) => {
        const name = namePrefix + idx
        return (<Slide imgContent={s} name={name} activeSlide={activeSlide}/>) 
    })

    return (
        <div className="rubiaImg">
            {slideDivs}
        </div>
      )
}