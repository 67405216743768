import React from "react";
import Track from "./player/Track";

import "./player/player.css"

import Play from "./player/Play";
import Pause from "./player/Pause";
import Bar from "./player/Bar";
import { GetApp } from "@material-ui/icons";
import parse from "html-react-parser";

import useAudioPlayer from './useAudioPlayer';

export default function Audio({name, source, tracks}) {
  const { curTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer(name);
  const [tracksShowing, setTracksShowing] = React.useState(false)
  const [curTrack, setCurTrack] = React.useState(tracks[0])
  const loadFn = () => document.getElementById(name).load()
  
  const trackDivs = tracks.map((track, idx) => {
    return (<Track trackIdx={idx} trackInfo={track} setTracksShowing={setTracksShowing}
                   setCurTrack={setCurTrack} loadFn={loadFn}/>)
  })

  return (
    <div className="player">
      <audio id={name}>
        <source src={curTrack.source} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      {source && <img className="image" src={source} />}
      {tracks.length > 1 &&
        <button className="tracks-button" onClick={() => setTracksShowing(prev => !prev)}>
          <GetApp/>
        </button>
      }
      {tracksShowing && 
        <div className="tracks">
          {trackDivs}
        </div>}
      <div className="controls">
        {playing ? 
          <Pause handleClick={() => setPlaying(false)} /> :
          <Play handleClick={() => setPlaying(true)} />
        }
        <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)}/>
        <div className="cur__song"><b>Now playing:</b> {parse(curTrack.name)}</div>
      </div>
    </div>
  );
}

