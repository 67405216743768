import React, {useContext} from "react";
import { CSSTransition } from "react-transition-group";
import { TwddContext } from "./TabWDropdown";

export default function Menu ({name, children, menuClass}) {

    const {activeMenu, setMenuHeight} = useContext(TwddContext)
    const mClass = menuClass || "menu-primary"
    
    function calcHeight(el) {
        const height = el.offsetHeight;
        setMenuHeight(height);
    }

    return (
        <CSSTransition
            in={activeMenu === name}
            unmountOnExit
            timeout={400}
            classNames={mClass}
            onEnter={calcHeight}
        >
            <div className="menu">
                {children}
            </div>
        </CSSTransition>
    )
}