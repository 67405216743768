import React, {useEffect, useRef} from "react"
import Audio from "./Audio"

export default function DuoAlmaPane() {
    return (
        <div>
            <p>The Duo Alma came about when Rúbia met Costa Rican violinist Lourdes Lobo, in 2017 as they were 
                adjudicating the Competition for Young Soloists at the University of Costa Rica. Shortly after, 
                both artists started working together to promote Latin American music and the standard works of 
                the genre.  Among the highlights so far are performances of music by Brazilian composers and the 
                Beethoven’ s Cycle of Sonatas for the Duo. </p>
             <div className="center-iframe" style={{marginTop: "20px"}}>
                <iframe
                    width="560" height="315" src="https://www.youtube.com/embed/1X1gfgUMU0A" 
                    title="YouTube video player" frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                </iframe>
            </div>
            <div className="center-iframe" style={{marginTop: "20px"}}>
                <iframe
                    width="560" height="315" src="https://www.youtube.com/embed/Firn1OK6txI" 
                    title="YouTube video player" frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                </iframe>
            </div>
            <div className="center-iframe" style={{marginTop: "20px"}}>
                <iframe
                    width="560" height="315" src="https://www.youtube.com/embed/m-L4oxkjUoE" 
                    title="YouTube video player" frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                </iframe>
            </div>
            <div className="center-iframe" style={{marginTop: "20px"}}>
                <iframe
                    width="560" height="315" src="https://www.youtube.com/embed/yr3jUPRDFZs" 
                    title="YouTube video player" frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                </iframe>
            </div>
        </div>
    )
}
