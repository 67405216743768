import React, {useState, createContext} from 'react'

export const TwddContext = createContext()

export default function TabWDropDown ({text, children, defaultMenu, setTabsPaneFn, setSelFn, selFn}) {
    // console.log("Render TabWDropDown")

    const [menuOpen, setMenuOpen] = useState(false)
    const [activeMenu, setActiveMenu] = useState("main");
    const [menuHeight, setMenuHeight] = useState(null);

    return (
        <div className="tab-holder">
            <div className="tab" onClick={(e) => {
                setMenuOpen(prev => !prev)
                setTabsPaneFn({tab: e.target, closeFn: setMenuOpen})
                e.currentTarget.style.backgroundColor = "#b38cee"
                }}>
                {text}
            </div>
            {menuOpen && 
                <div className="dropdown" style={{ height: menuHeight }}>
                    <TwddContext.Provider 
                        value={{menuOpen, 
                                setMenuOpen, 
                                activeMenu, 
                                setActiveMenu, 
                                menuHeight, 
                                setMenuHeight,
                                setSelFn}}>
                    {children}
                    </TwddContext.Provider>
              </div>}
        </div>
    )
}