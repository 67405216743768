import React from 'react'
import { CSSTransition } from "react-transition-group";

export default function Slide({ imgContent, menuClass, name, activeSlide }) {
    // console.log("Render Slide " + name)

    const mClass = menuClass || "slide"
    return (
        <CSSTransition
            in={activeSlide === name}
            unmountOnExit
            timeout={5000}
            classNames={mClass}
        >
            <div className="slide" style={{ backgroundImage: `url(${imgContent})` }}/>
        </CSSTransition>
    )
}