import React from "react"

export default function Tabs({children}) {
    // console.log("Render Tabs")

    return (
        <div className="tab-container">
            {children}
        </div>
    )
}