export const RecData = [
    { img: require("../images/HigdonsCM.jpg"),
      paragraphs: ["Legacy for violin and piano is yet another example of why Jennifer’s music is so directly appealing. This is a straight forward neo-Romantic musing that the composer states is intended to represent “life’s wholeness, the good and the bad…”  This elegant work is played with great emotion by John Fadial, violin with Rubia Santos, piano",
            "Trumpet Songs has a similar lineage, being a set of nine melodies from art songs Higdon composed some years earlier.  There is definitely a lyrical quality to these lovely little pieces and Scott Meredith, trumpet, with Rubia Santos on piano has a wonderful classical tone that carries the melodies in a gorgeous, unassuming way",
            "--Daniel Coombs (Audiophile Audition Magazine)"],
      tracks: [{source: require("../audiovideo/Legacy.mp3"), name: "Higdon - Legacy"},
               {source: require("../audiovideo/TrumpetSongs.mp3"), name: "Hop and Toe (World Premiere Recording)"}]},
    { img: require("../images/Moyugba.jpeg"),
      paragraphs: ["Several of the recordings are world premieres, including the title work and Japanese-American composer Paul Chihara's \"Sonata for Viola and Piano.\"  Joining Juan-Miguel Hernandez (the viola in the Harlem String Quartet) on this release is CMU faculty member Rúbia Santos. Her pianism contributes culturally to the album, Santos hailing from Brazil!"],
      tracks: [{source: require("../audiovideo/Chihara.mp3"), name: "Chihara - Sonata for Viola and Piano ('De Profundis') <br>II. Tempo di Menuetto"},
               {source: require("../audiovideo/VCSonataforViolaPiano1.mp3"), name: "Villani-Cortes - Sonata for Viola and Piano,<br>I. Moderato 1"}]},
    { img: require("../images/ABrazilianCollection.jpg"),
      paragraphs: ["This unique collection has just the right balance of lyrical melodies and saucy rhythms that will please lovers of both classical and Latin American music. A must-have for even those with little or no musical background but who appreciate good music. Many of the works included were written for the performer himself and make their debut with this album. The quality of this performance is excellent” (Connor Smith)"],
      tracks: [{source: require("../audiovideo/Duda1.mp3"), name: "Duda - Suite Recife, \"Andrea\" (Baião)"}]},
    { img: require("../images/PeresDeCampos.jpg"),
      paragraphs: [],
      tracks: [{source: require("../audiovideo/PeresAllegro.mp3"), name: "Pires de Campos - Allegro para violino e piano"}]},
    { img: require("../images/Guarnieri.jpg"),
      paragraphs: [],
      tracks: [{source: require("../audiovideo/GuarnieriSonata4-II.mp3"), name: "Camargo Guarnieri - Sonata no. 4 for Violin and Piano, <br>II. Intimo"},
               {source: require("../audiovideo/GuarnieriSonata4-III.mp3"), name: "Camargo Guarnieri - Sonata no. 4 for Violin and Piano, <br>III. Allegro appassionato"}]}

]