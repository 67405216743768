export const repData = {
    saxophone: [
          { composer: "Albright", 
            titles: ["Sonata"]
          },
          {composer: "Biedenbender",
           titles: ["Images"] 
          },
          {composer: "BolCom",
           titles: ["Lilith"]
          },
          {composer: "Boutry",
           titles: ["Divertimento"]
          },
          {composer: "Bozza",
           titles: ["Aria"]
          },
          {composer: "Creston",
           titles: ["Sonata"]
          },
          {composer: "Debussy",
           titles: ["Rhapsodie"]
          },
          {composer: "Decruck",
           titles: ["Sonata in C Major"]
          },
          {composer: "Denisov",
           titles: ["Sonata"]
          },
          {composer: "Dubois",
           titles: ["Concerto with Orchestra"]
          },
          {composer: "Feld",
           titles: ["Sonata"]
          },
          {composer: "Glazunov",
           titles: ["Concerto, Op. 109"]
          },
          {composer: "Gotkovsky",
           titles: ["Brilliance"]
          },
          {composer: "Granados",
           titles: ["Intermezzo (Goyescas)"]
          },
          {composer: "Guzzo",
           titles: ["Seeking Ithaca"]
          },
          {composer: "Heiden",
           titles: ["Sonata"]
          },
          {composer: "Hulsa",
           titles: ["Elegie et Rondea"]
          },
          {composer: "Ibert",
           titles: ["Concertino da Camera"]
          },
          {composer: "Maslanka",
           titles: ["Sonata"]
          },
          {composer: "Marcello",
           titles: ["Concerto in C minor"]
          },
          {composer: "Maurice",
           titles: ["Tableaux de Provence"]
          },
          {composer: "Mays",
           titles: ["Concerto for Saxophone"]
          },
          {composer: "Milhaud",
           titles: ["Scaramouche"]
          },
          {composer: "Rachmaninoff",
           titles: ["Vocalise", "Prelude No. 4"]
          },
          {composer: "Riordan",
           titles: ["Sonata"]
          },
          {composer: "Ruggiero",
           titles: ["Strayhorn"]
          },
          {composer: "Schubert",
           titles: ["Serenade (Schwanengesang)"]
          },
          {composer: "Swerts",
           titles: ["Klonos"]
          },
          {composer: "Villa-Lobos",
           titles: ["Fantasy for Sax Soprano"]
          },
          {composer: "Yoshimaka",
           titles: ["Fuzzy Bird Sonata"]
          },
          {composer: "Worley",
           titles: ["Sonata"]
          }
        ],
        flute: [
          {composer: "Bach",
           titles: ["Sonata in B minor, BWV 1030", "Sonata in G minor, BWV 1020"]
          },
          {composer: "Copland",
           titles: ["Duo for Flute and Piano"]
          },
          {composer: "Couprin",
           titles: ["Concert Royaux - Quatrieme Concert"]
          },
          {composer: "Enescu",
           titles: ["Cantabile and Presto"]
          },
          {composer: "Fauré",
           titles: ["Fantasy"]
          },
          {composer: "Foss",
           titles: ["Three American PIeces"]
          },
          {composer: "Griffes",
           titles: ["Poem"]
          },
          {composer: "Hanson",
           titles: ["Serenade"]
          },
          {composer: "Hue",
           titles: ["Fantasia"]
          },
          {composer: "Ibert",
           titles: ["Flute Concerto"]
          },
          {composer: "Jolivet",
           titles: ["Chants de Linos", "Sonatina"]
          },
          {composer: "Martin",
           titles: ["Balade"]
          },
          {composer: "Martinu",
           titles: ["Sonatina"]
          },
          {composer: "Messian",
           titles: ["Le merle noir"]
          },
          {composer: "Mozart",
           titles: ["Concerto in G Major, K. 313"]
          },
          {composer: "Mower",
           titles: ["Sonata Latino"]
          },
          {composer: "Muczynski",
           titles: ["Sonata"]
          },
          {composer: "Poulenc",
           titles: ["Sonata"]
          },
          {composer: "Prokofiev",
           titles: ["Sonata"]
          },
          {composer: "Reinecke",
           titles: ["Sonata \"Undine\""]
          },
          {composer: "Rodrigo",
           titles: ["Fantasia para um gentilhombre"]
          },
          {composer: "Sancan",
           titles: ["Sonatine"]
          },
          {composer: "Schubert",
           titles: ["Introduction & Variations over and Original Theme, \"Trockne Blumen\""]
          }
    ],
    clarinet: [
        {composer: "Arnold",
           titles: ["Sonatina"]
          },
          {composer: "Brahms",
           titles: ["Sonata Op. 120, No.1 in Eb Major", "Sonata Op. 120, No.2 in F minor"]
          },
          {composer: "Busser",
           titles: ["Pastorale, Op. 16"]
          },
          {composer: "Debussy",
           titles: ["Première rhapsodie"]
          },
          {composer: "Dubois",
           titles: ["Nein Impromptus"]
          },
          {composer: "Genzmer",
           titles: ["Sonatine"]
          },
          {composer: "Guzzo",
           titles: ["Conigli"]
          },
          {composer: "Lutoslawski",
           titles: ["Dance Preludes"]
          },
          {composer: "Martinu",
           titles: ["Sonata"]
          },
          {composer: "Mendelssohn",
           titles: ["Concertpiece, Op. 114"]
          },
          {composer: "Milhaud",
           titles: ["Duo Concertant"]
          },
          {composer: "Muczynski",
           titles: ["Time Pieces"]
          },
          {composer: "Poulenc",
           titles: ["Sonata"]
          },
          {composer: "Rivera",
           titles: ["Vlas Venezolano", "Contradanza"]
          },
          {composer: "Saint-Saëns",
           titles: ["Sonata, Op. 167"]
          },
          {composer: "Schumann",
           titles: ["Phantasiestücke"]
          },
          {composer: "Shapiro",
           titles: ["Intermezzo"]
          },
          {composer: "Weber",
           titles: ["Concertino in E-flat Major", "Introduction, Theme and Variations", "Concerto No. 1, in F minor"]
          },
          {composer: "Williams",
           titles: ["Viktor'sTale from \"The Terminal\""]
          }
        ],
    oboe: [
        {composer: "Abinoni",
           titles: ["Concerto in D"]
        },
        {composer: "Busser",
           titles: ["Éclogue"]
        },
        {composer: "Corbett (Harpsichord)",
           titles: ["Sonata in C Major (with Turmpet)"]
        },
        {composer: "Handel",
           titles: ["Sonata in C, Op. 1, No. 8"]
        },
        {composer: "Hindemith",
           titles: ["Sonata"]
        },
        {composer: "McGee",
           titles: ["Synconpated Stars (with Clarinet)"]
        },
        {composer: "Marcello",
           titles: ["Concerto"]
        },
        {composer: "Mozart",
           titles: ["Concerto in C Major, K. 314"]
        },
        {composer: "Poulenc",
           titles: ["Sonata"]
        },
        {composer: "Rubbra",
           titles: ["Sonata in C"]
        },
        {composer: "Sancan",
           titles: ["Sonata in D"]
        },
        {composer: "Schumann",
           titles: ["Three Romances"]
        },
        {composer: "Strauss",
           titles: ["Concerto"]
        }
    ],
    bassoon: [
        {composer: "Bozza",
           titles: ["Recitative, Sicilienne et Rondo"]
        },
        {composer: "Couprin",
           titles: ["Fantasie"]
        },
        {composer: "Elgar",
           titles: ["Romance"]
        },
        {composer: "Hindemith",
           titles: ["Sonata"]
        },
        {composer: "Koechlin",
           titles: ["Sonata, Op. 71"]
        },
        {composer: "Weber",
           titles: ["Andante and Hungarian Rondo, Op. 35"]
        }
    ],
    trumpet: [
        {composer: "Aruturian",
           titles: ["Concerto"]
        },
        {composer: "Barat",
           titles: ["Andante et Scherzo"]
        },
        {composer: "Bozza",
           titles: ["Rustiques"]
        },
        {composer: "Enescu",
           titles: ["Legend"]
        },
        {composer: "Gabaye",
           titles: ["Feu d' artifice"]
        },
        {composer: "Galliard",
           titles: ["Sonatas"]
        },
        {composer: "Gabrieli",
           titles: ["Sonata no. 5"]
        },
        {composer: "Goedicke",
           titles: ["Concerto, Op. 41"]
        },
        {composer: "Haydn",
           titles: ["Concerto in E-flat Major"]
        },
        {composer: "Higdon",
           titles: ["Trumpet Songs"]
        },
        {composer: "Honneger",
           titles: ["Intrata"]
        },
        {composer: "Humnel",
           titles: ["Concerto  in E-flat Major"]
        },
        {composer: "Mahler",
           titles: ["Songs of a Wayfahrer (I-IV)"]
        },
        {composer: "Pilss",
           titles: ["Sonata"]
        },
        {composer: "Proto",
           titles: ["A Carmen Fantasy"]
        },
        {composer: "Robbins",
           titles: ["Monte St. Michel"]
        },
        {composer: "Ropartz",
           titles: ["Andante and Allegro"]
        },
        {composer: "Rossini",
           titles: ["Rosina's Cavatina: Una voce poco fa"]
        },
        {composer: "Scarlatti",
           titles: ["Aria Si suoni la tromba"]
        },
        {composer: "Tartini",
           titles: ["Concerto in D"]
        },
        {composer: "Torelli",
           titles: ["Concert in D"]
        },
        {composer: "Telemann/Cooper",
           titles: ["Sonata No. 3"]
        },
        {composer: "Tomasi",
           titles: ["Concerto"]
        }
    ],
    trombone: [
        {composer: "Bach",
           titles: ["Sonata for Viola da Gamba and Harpsichord No.1 in G"]
        },
        {composer: "Bozza",
           titles: ["Ballade, Op. 62"]
        },
        {composer: "Castérède",
           titles: ["Sonatine"]
        },
        {composer: "Innes",
           titles: ["Fantasia \"Comin Through the Rye\""]
        },
        {composer: "Lebedev",
           titles: ["Concerto in One Movement for Bass Trombone"]
        },
        {composer: "Marcello",
           titles: ["Sonata in F Major"]
        },
        {composer: "Plog",
           titles: ["Three Miniatures"]
        },
        {composer: "Rachmaninoff",
           titles: ["Elegie, Op. 3"]
        },
        {composer: "Sulek",
           titles: ["Sonata (Vox Gabrielli)"]
        }
    ],
    horn: [
        {composer: "Beethoven",
           titles: ["Sonata in F, Op. 17"]
        },
        {composer: "Bozza",
           titles: ["En forêt"]
        },
        {composer: "Glazunov",
           titles: ["Reveries"]
        },
        {composer: "Hindemith",
           titles: ["Sonata"]
        },
        {composer: "Mozart",
           titles: ["Concerto No. 3, in E-flat Major"]
        },
        {composer: "Schumann",
           titles: ["Adagio and Allegro, Op. 70"]
        },
        {composer: "Strauss",
           titles: ["Horn Concerto"]
        }
    ],
    tuba: [
        {composer: "Hindemith",
           titles: ["Sonata for Bass Tuba"]
        },
        {composer: "Williams",
           titles: ["Six Studies in English Folksong", "Concerto for Bass Tuba"]
        }
    ],
    violin: [
        {composer: "Albeniz",
         titles: ["Tango"]
        },
        {composer: "Bach",
           titles: ["Sonata No. 1, BWV 1001", "Sonata No. 6, BWV 1019"]
        },
        {composer: "Bartók",
           titles: ["Six Roumanian Folk Dances"]
        },
        {composer: "Beethoven",
           titles: ["Sonatas:", "Op, 30, nos 1, 2, 3", "Op. 24, “Spring”", "Op. 12, No. 1", "Op, 47, \"Kreutzer\"", "Op. 96"]
        },
        {composer: "Block",
           titles: ["Nigun"]
        },
        {composer: "Brahms",
           titles: ["Sonata No.1, Op. 78", "Sonata No.2, Op. 100", "Sonata No.3, Op. 108", "Scherzo in C minor"]
        },
        {composer: "Chausson",
           titles: ["Poème"]
        },
        {composer: "Corelli",
           titles: ["La Follia"]
        },
        {composer: "Debussy",
           titles: ["Sonata"]
        },
        {composer: "Dvorak",
           titles: ["Sonatina, Op. 100", "Romance, Op.11"]
        },
        {composer: "Enescu",
           titles: ["Sonata No. 3"]
        },
        {composer: "Falla",
           titles: ["Suite populaire espagnole"]
        },
        {composer: "Fauré",
           titles: ["Sonata Op.13"]
        },
        {composer: "Fine",
           titles: ["Sonata"]
        },
        {composer: "Franck",
           titles: ["Sonata"]
        },
        {composer: "Ginastera",
           titles: ["Pampeana no. 1"]
        },
        {composer: "Granados",
           titles: ["Danzas españolas"]
        },
        {composer: "Grieg",
           titles: ["Sonata No. 3"]
        },
        {composer: "Handel",
           titles: ["Sonata No. 4"]
        },
        {composer: "Higdon",
           titles: ["Legacy"]
        },
        {composer: "Kreisler",
           titles: ["Liebesleid", "Zigeuner-Capriccio", "The Devil's Trill"]
        },
        {composer: "Kroll",
           titles: ["Banjo and Fiddle"]
        },
        {composer: "Leclair",
           titles: ["Sonata Op. 9"]
        },
        {composer: "Mozart",
           titles: ["Sonatas:", "K. 301, G Major", "K. 304, E minor", "K. 379, G Major", "B-flat Major", "Rondo in C, K. 373"]
        },
        {composer: "Piazzolla",
           titles: ["Adios, Nonino"]
        },
        {composer: "Poulenc",
           titles: ["Sonate"]
        },
        {composer: "Prokofiev",
           titles: ["Sonata No. 1, Op. 80", "Sonata No. 2, Op. 94"]
        },
        {composer: "Pugnani-Kreisler",
           titles: ["Prelude and Allegro"]
        },
        {composer: "Ravel",
           titles: ["Tzigane, Rapsodie de Concert", "Sonate posthume"]
        },
        {composer: "Saint-Saëns",
           titles: ["Introduction and Rondo Caprice, Op. 28"]
        },
        {composer: "Sarasate",
           titles: ["Zigeunerweisen", "Carmen Fantasia"]
        },
        {composer: "Schubert",
           titles: ["Sonatina, Op. 137"]
        },
        {composer: "Schumann",
           titles: ["Sonata, Op. 105", "Sonata, Op. 121"]
        },
        {composer: "Smetana",
           titles: ["My Home Land"]
        },
        {composer: "Strauss",
           titles: ["Sonata, Op. 18"]
        },
        {composer: "Tartini-Kreisler",
           titles: ["Variations on a Theme by Corelli"]
        },
        {composer: "Terzakis",
           titles: ["Etude chromatique"]
        },
        {composer: "Vitali",
           titles: ["Chaconne"]
        },
        {composer: "Webern",
           titles: ["Four Pieces, Op. 7"]
        },
        {composer: "Wieniawski",
           titles: ["Scherzo-Tarantelle, Op. 16", "Polonaise Brilliante, Op. 21"]
        }
    ],
    viola: [
      {composer: "Bach (Harpsichord)",
         titles: ["Sonatas  for Viola da Gamba No.1 in G, No. 2 in D, and No. 3 in G"]
      },
      {composer: "Block",
         titles: ["Suite Hebraica"]
      },
      {composer: "Brahms",
         titles: ["Sonata Op. 120, No. 1 in Eb Major", "Sonata Op. 120, No. 2 in F minor"]
      },
      {composer: "Bridge",
         titles: ["Pensiero"]
      },
      {composer: "Britten",
         titles: ["Lachrymae"]
      },
      {composer: "Bruch",
         titles: ["Romance"]
      },
      {composer: "Chihara",
         titles: ["Sonata"]
      },
      {composer: "Clarke",
         titles: ["Passacaglia on an Old English Tune", "Sonata (1919)"]
      },
      {composer: "Handel",
         titles: ["Sonata in C"]
      },
      {composer: "Hindemith",
         titles: ["Sonata Op. 11, No. 4"]
      },
      {composer: "Schumann",
         titles: ["Märchenbilder, Op. 113"]
      },
      {composer: "Vitali",
         titles: ["Ciaccona"]
      },

    ],
    cello: [
      {composer: "Bach (Harpsichord)",
         titles: ["Sonatas  for Viola da Gamba and Harpsichord No.1 in G, No. 2 in D and No. 3 in G"]
      },
      {composer: "Beethoven",
         titles: ["Sonatas:", "C Major, Op. 102, No.1", "F Major, Op. 5  No. 1", "G minor, Op. 5 No. 2", "A Major, Op. 69", "Twelve Variations on \"Ein Mädchen oder Weibchen\", Op. 66"]
      },
      {composer: "Brahms",
         titles: ["Schelomo", "Sonata in E minor, Op. 38", "Sonata in F Major, Op. 99"]
      },
      {composer: "Britten",
         titles: ["Cello Sonata, H. 125"]
      },
      {composer: "Bloch",
         titles: ["Koi Nidrei", "Schelomo"]
      },
      {composer: "Cassado",
         titles: ["Requiebros"]
      },
      {composer: "Falla",
         titles: ["Danse Rituelle du Feu"]
      },
      {composer: "Fauré",
         titles: ["Elegie"]
      },
      {composer: "Franck",
         titles: ["Sonata in A Major"]
      },
      {composer: "Francoeur",
         titles: ["Sonata in E Major"]
      },
      {composer: "Grieg",
         titles: ["Sonata in A minor, Op. 36"]
      },
      {composer: "Handel",
         titles: ["Sonata for Two Cellos in G minor"]
      },
      {composer: "Kodály",
         titles: ["Sonatina"]
      },
      {composer: "Locatelli",
         titles: ["Sonata in D Major"]
      },
      {composer: "Martinu",
         titles: ["Theme and Variations on a Theme by Rossini", "Variations on a Slovakian Theme"]
      },
      {composer: "Mendelssohn",
         titles: ["Song Without Words, Op. 109", "Sonata No. 1 in B Major, Op. 45", "Sonata No. 2, in D Major, Op. 58"]
      },
      {composer: "Paganini",
         titles: ["Bravura Variations on a Rossini's Theme from \"Moses\""]
      },
      {composer: "Piazolla",
         titles: ["Le Grand Tango"]
      },
      {composer: "Prokofiev",
         titles: ["Sonata in C Major, Op. 119"]
      },
      {composer: "Rachmaninoff",
         titles: ["Sonata in G minor, Op. 19"]
      },
      {composer: "Schubert",
         titles: ["Arpeggione Sonata"]
      },
      {composer: "Saint-Saëns",
         titles: ["Suite italienne"]
      },
      {composer: "Schumann",
         titles: ["Fantasiestücke, Op. 73", "Adagio and Allegro Op. 120", "Five Pieces in Folkstyle, Op. 102"]
      },
      {composer: "Shostakovich",
         titles: ["Sonata, Op. 40"]
      },
      {composer: "Tchaikovsky",
         titles: ["Pezzo capriccioso, Op. 62", "Andante cantabile"]
      },
      {composer: "Webern",
         titles: ["Drei Kleine Stücke, Op. 11"]
      }

    ],
    bass: [
      {composer: "Brahms",
         titles: ["Sonata in E minor (original for cello and piano)", "Proto", "A Carmen Fantasy", "CONCERTOS WITH ORCHESTRA (Piano Reduction)"]
      },
      {composer: "Bach",
         titles: ["Concerto for Two Violins in D minor, BWV 1043", "Violin Concerto in E Major, BWV 1042"]
      },
      {composer: "Barber",
         titles: ["Violin Concerto, Op. 14"]
      },
      {composer: "Bottesini",
         titles: ["Double Bass Concerto No. 2 in B minor"]
      },
      {composer: "Brahms",
         titles: ["Concerto for Violin and Orchestra in D, Op. 77"]
      },
      {composer: "Bruch",
         titles: ["Concerto No. 1 in G minor, Op. 26"]
      },
      {composer: "Chauson",
         titles: ["Poème for Violin and Orchestra"]
      },
      {composer: "Couperin",
         titles: ["Concerto for Two Cellos"]
      },
      {composer: "Davidoff",
         titles: ["Concerto for Double Bass in E Major", "Cello Concerto No.1 in B nimor, Op. 5"]
      },
      {composer: "Dittersdorf",
         titles: ["Double Bass Concerto in B minor, Op. 104"]
      },
      {composer: "Dvorák",
         titles: ["Concerto for Double Bass in E Major", "Cello Concerto in E minor, Op. 85"]
      },
      {composer: "Elgar",
         titles: ["Cello Concerto in E minor, Op. 85"]
      },
      {composer: "Glazunov, A.",
         titles: ["Violin Concerto in A minor, Op. 82"]
      },
      {composer: "Haydn",
         titles: ["Celloi Concerto in C Major, No. 1"]
      },
      {composer: "Kabalewsky",
         titles: ["Violin Concerto in C Major, Op. 48"]
      },
      {composer: "Khachaturian",
         titles: ["Violin Concerto in D minor, Op. 46"]
      },
      {composer: "Koussevitzky",
         titles: ["Double Bass Concerto in  F-sahrp minor, Op. 3"]
      },
      {composer: "Lalo",
         titles: ["Symphonie Espagnole, Op. 21"]
      },
      {composer: "Mendelssohn",
         titles: ["Violin Concerto in E minor, Op. 64"]
      },
      {composer: "Mozart",
         titles: ["Violin Concertos Nos 1, 2, 3, 4 , 5", "Sinfonia concertante for Violin and Viola in E-flat, K.  364"]
      },
      {composer: "Romberg",
         titles: ["Cello Concerto in D Major, Op. 3"]
      },
      {composer: "Saint-Saëns",
         titles: ["Concerto No. 3 in B minor, Op. 61", "Concerto No. 1 in A minor, Op."]
      },
      {composer: "Schumann",
         titles: ["Cello Concerto in A minor, Op. 129"]
      },
      {composer: "Sibelius",
         titles: ["Violin Concerto in D minor, Op. 47"]
      },
      {composer: "Shostakovich",
         titles: ["Concerto for Cello and Orchestra No. 1, Op. 107"]
      },
      {composer: "Stamitz",
         titles: ["Viola Concerto in D Major, Op. 1"]
      },
      {composer: "Tchaikovsky",
         titles: ["Violin Concerto in D Major, Op. 35", "Variations on a Rococo Theme for Cello and Orchestra"]
      },
      {composer: "Walton",
         titles: ["Concerto for Viola and Orchestra"]
      }
    ],
    trios: [
      {composer: "Beethoven",
         titles: ["Trio in E-flat Major,Op. 1, No. 1", "Trio in B-flat Major, Op. 11", "Trio in B-flat Major, \"Archduke,\" Op. 97"]
      },
      {composer: "Brahms",
         titles: ["Trio in A minor, Op. 114", "Trio in E-flat Major Op. 40", "Trio in B Major, Op. 8"]
      },
      {composer: "Bottesini",
         titles: ["Two Songs, For Soprano, Double-bass, and Piano"]
      },
      {composer: "Corbett",
         titles: ["Sonata in D Major, Op. 1 No. 12"]
      },
      {composer: "Debussy",
         titles: ["Trio in G Major"]
      },
      {composer: "Haydn",
         titles: ["Trio in G Major, \"Gypsy,\" Hob. XV:25", "Trio In A Major, Hob. XV: 18"]
      },
      {composer: "Loeffler",
         titles: ["Two Rhapsodies for Oboe, Viola, and Piano"]
      },
      {composer: "Mendelssohn",
         titles: ["Piano Trio Op. 49, D minor"]
      },
      {composer: "Mozart",
         titles: ["\"Kegelstatt;Trio\" in Eb Major  K. 498", "Trio in C Major , K. 548", "Trio In G Major, K. 564"]
      },
      {composer: "Park",
         titles: ["Piano Trio \"Scent of Lilac\""]
      },
      {composer: "Poulenc",
         titles: ["Trio pour piano, hautbois et bassoon"]
      },
      {composer: "Rachmaninoff",
         titles: ["Trio élégiaque No. 1, G minor"]
      },
      {composer: "Reinecke",
         titles: ["Trio for Oboe. Horn, and Piano"]
      },
      {composer: "Shih",
         titles: ["Piano Trio, \"Ode To Life\""]
      },
      {composer: "Schubert",
         titles: ["Trio for Clarinet, Piano and Soprano, \"The Sheperd on the Rock\"", "Notturno in E-falt Major, Op. 148", "Piano Trio in B-falt Majo, D. 898", "Piano Trio in E-fla Major, D. 929"]
      },
      {composer: "Thomas-Mifune",
         titles: ["Four Tangos for Piano Trio, Vol 1 and 2"]
      },
      {composer: "Turina",
         titles: ["Trio No. 2, Op. 76 in B minor"]
      },
      {composer: "Wilder",
         titles: ["Suite for Clarinet, Basson, and Piano"]
      }
   ],
   quartet: [
      {composer: "Fauré",
         titles: ["Piano Quartet in C minor, Op. 15"]
      },
      {composer: "Saint-Saens",
         titles: ["Caprice on Danish and Russian Aris, Op. 70"]
      },
      {composer: "Schumann",
         titles: ["Sonata Piano Quartet in E-flat, Op. 47"]
      }
   ],
   quintet: [
      {composer: "Schubert",
         titles: ["Quintet for Strings and Piano, \"The Trout,\" D. 667"]
      },
      {composer: "Schumann",
         titles: ["Piano Quintet in E-flat, OP. 44"]
      },
      {composer: "Sibelius",
         titles: ["Quintet for Piano and Strings in G minor"]
      }
   ],
   sextet: [
      {composer: "Adolphe",
         titles: ["The Tiger's Ear: Listening to Abstract Expressionist Paintings"]
      },
      {composer: "Dohnanyi",
         titles: ["Sextet in C Major, op. 37"]
      },
      {composer: "Poulenc",
         titles: ["Sextet for Piano and Wind Quintet"]
      },
      {composer: "Prokofiev",
         titles: ["Overture on Hebrew Themes, Op. 34 for Clarinet, String Quartet, and Piano"]
      },
      {composer: "Thuille",
         titles: ["Sextet Op. 6"]
      }
   ],
   'large ensembles': [
      {composer: "B. Bevelander",
         titles: ["A Letter From Nathaniel Giles"]
      },
      {composer: "H. Bielawwa",
         titles: ["More Walking on Uneven Ground"]
      },
      {composer: "A. Davis",
         titles: ["Lost Moon Sisters"]
      },
      {composer: "M. Nobre",
         titles: ["Sonâncias III for Two Pianos and Two Percussionists"]
      }
   ],
   "complete operas": [
      {composer: "Argento",
         titles: ["A Water Bird Talk, A Mono Opera"]
      },
      {composer: "Menotti",
         titles: ["The Medium"]
      },
      {composer: "Mozart",
         titles: ["The Magic Flute", "The Marriage of Figaro"]
      },
      {composer: "Puccini",
         titles: ["Gianni Schicchi", "Madame Butterfy", "La Bohème"]
      },
      {composer: "Purcell",
         titles: ["Dido and Aeneas"]
      },
      {composer: "Ravel",
         titles: ["L' Enfant et les Sortilèges"]
      },
      {composer: "Weill (Orchestra Pianist)",
         titles: ["Die sieben Todsünden"]
      }
   ],
   arias: [
      {composer: "Beethoven",
         titles: ["O wär' ich mit dir vereint (Fidelio)", "Gott! wech' Dunlel hier!  (Fidelio)"]
      },
      {composer: "Bellini",
         titles: ["Casta Diva (Norma)", "MIra, o Norma (Duet)"]
      },
      {composer: "Bernstein",
         titles: ["Glitter and Be Gay"]
      },
      {composer: "Bizet",
         titles: ["The flower song  (Carmen)", "Pres des remparts de Seville Carmen)", "L'amour est un oiseau rebelle (Carmen)", "Toreador Song (Carmen)"]
      },
      {composer: "Delibes",
         titles: ["The Flower duet (Lakmé)"]
      },
      {composer: "Dvorak",
         titles: ["Song to the Moon (Rusalka)"]
      },
      {composer: "Donizetti",
         titles: ["Una furtiva lagrima (L'Elisir d' amore)", "Bella sicome un angelo (Don Pasquale)", "Mad Scene (Lucia di Lammermoor)"]
      },
      {composer: "Floyd",
         titles: ["Ain't it a pretty night? (Suzannah)", "The trees on the mountain are cold and bare (Suzannah)"]
      },
      {composer: "Gounod",
         titles: ["Je veux vivre (Romeo et Juliette)", "Ah, je ris de me voir [Jewel Song] (Romeo and Juliett)", "Salut! demeure chaste et pure (Romeo and Juliett)", "Avant de quitter ces lieux (Romeo and Juliet)"]
      },
      {composer: "Handel",
         titles: ["Ombra mai fui (Serse)", "Va Godendo (serse)", "Va Godendo (serse)", "Cara speme (Giulio Cesare)", "V' adoro pupille (Giulio Cesare)", 
                  "Piangerò la sorte mia (Giulio Cesare)", "Lascia ch'io pianga (Rinaldo)", "Bel piacere (Agrippina)",
                  "Cara selve (Atalanta)", "Where'er you walk (Semele)"]
      },
      {composer: "Massenet",
         titles: ["Adieu, notre petite table (Manon)", "Je suis encor tout étourdie (Manon)", "Va laisse couler mes larmes (Werther)"]
      },
      {composer: "Mozart",
         titles: ["Parto, parto (La Clemenza di TIto)", "Madamina(Don GIovanni)", "Batti, batti, o bel Masetto (Don Giovanni)", "Vedrai Carino (Don Giovanni)",
                  "Dalla sua pace (Don Giovanni)", "Il mio tesoro (Don Giovanni)", "Deh, vieni a la finestra (Don Giovanni)", "MI traid quell'alma ingrata (Don Giovanni)",
                  "Come scoglio (Cosi fan tutte)", "Smanie implacabili (Cosi fan tutte)", "Una aura amorosa (Cosi fan tutte)", "Donne mie, le fate a tanti (Cosi fan tutte)",
                  "Una donna a quindici anni (Cosi fan tutte)", "Zeffiretti lusinghieri (Idomeneo)", "Durch Zärtliche und Schmeicheln (The Abduction of the Seraglio)",
                  "Ach, ich liebte (The Abduction of the Seraglio)", "Konstanze, wie ängstlich (THe Abduction of the Seraglio)"]
      },
      {composer: "Moore",
         titles: ["Warm as the Automn Light (Ballad of Baby Doe)", "Willow Song (Ballad of Baby Doe)", "The Silver Aria (Ballad of Baby Doe)"]
      },
      {composer: "Offenbach",
         titles: ["Elle a fui, la tourterelle! (Les Contes d'Hoffmann)", "Les oiseaux dans la charmille (Les Contes d'Hoffmann)", "Barcarolle  (Les Contes d'Hoffmann)"]
      },
      {composer: "Puccini",
         titles: ["Dal labbro mio (Edgar)", "Signore, ascolta! (Turandot)", "TU che di gel sei cinta (Turandot)", "Vissi d'Arte, Vissi d' Amore (Tosca)",
                  "Love duet  (Il Pagliacci)"]
      },
      {composer: "Rossini",
         titles: ["Cruda sorte!...Gia so per pratica (L'italiana in Algeri)", "Una voce poco fa...Io son docile (The Barber of Sevilla)", "Largo al factotum (The Barber of Sevillle)",
                  "A un dottor della mia sorte (The Barber of Seville", "Ecco ridente in cielo (The Barber oF Sevilla)", "Non più mesta (La cenerentola)"]
      },
      {composer: "Saint-Saens",
         titles: ["Mon coeur s'ouvre a ta voix (Samson et Dalila)"]
      },
      {composer: "J. Strauss",
         titles: ["SMein Herr Marquis (Die Fledermaus)", "The Watch Duet (the Fledermaus)", "Chacun a son gout (The Fledermaus", "Harlenkin's Aria (Ariadne auf Naxos)"]
      },
      {composer: "R. Strauss",
         titles: ["Zerbinetta 's Monologue (Ariadne)"]
      },
      {composer: "Stravisnky",
         titles: ["Since it is not by merit... (The Rake's Progress)"]
      },
      {composer: "Tchaikovsky",
         titles: ["Pauline's Aria (The Quenn of Spades"]
      },
      {composer: "Verdi",
         titles: ["Caro nome (Rigoletto)", "La donne e mobile (Rigoletto)", "Possente amor mi chiama (Rigoletto)", "Su fil d'un soffio etesio (Falstaff)",
                  "E strano!.e strano!...Ah! fors e lui que l'anima (La traviata)", "Folie! folie (La traviata)", "Deh miei bollenti spiriti (La traviata)",
                  "Eh grave il sacrifizio (La traviata)"]
      }
   ],
   'Art Songs': [
      {composer: "Bach",
      titles: ["Ich habe genug (Cantata)", "Bist du bei mir", "Ich folge dir gleichfalls mit freudigen Schritten", "Ach, mein Sinn",
               "Es ist vollbracht!", "Buss und Reu", "Ich will dir mein Herze schenken", "Ich will mein Jesu wachen", "Ach! nun ist mein Jesus hin!",
               "Ach! nun ist mein Jesus hin!", "Erbame dich, mein Gott", "Mache dich, mein Herze, rein", "Aus Liebe will mein Heiland sterbe", 
               "Deposuit potentes", "Qui fecit mihi magna"]
      },
      {composer: "Barber",
         titles: ["Un cygne", "Tombeau dans un parc", "Le clocher chante", "Sure in this Shining Night", "The Daisies"]
      },
      {composer: "Beethoven",
         titles: ["An die ferne Geliebte (Song Cycle)", "Adelaide", "Wonne der Wehmut", "Sehnsucht", "Mit einem gemalten Band", "Kennst du das Land"]
      },
      {composer: "Bellini",
         titles: ["La farfaletta", "Vaga luna, che inargenti", "Per pieta, bell' idol mio"]
      },
      {composer: "Bowles",
         titles: ["Heavenly Grass", "Lonesome Man", "Cabin", "Sugar in the Cane"]
      },
      {composer: "Bolcom",
         titles: ["Amor", "Black Max", "George", "Lime jellow marshmallow cottage cheese", "Waitin", "At the last lousy moments of love", "Surprise!"]
      },
      {composer: "Brahms",
         titles: ["Romanzen aus Tieck's Magelone (Song Cycle)", "Neue Lider und Gesänge (Collection)", "Liebeslieder Waltzer", "Vier Ernste Gesänge",
                  "Regenlied Cycle", "Two Songs for Alto, Viola, and Piano", "Acht Zigeunerlieder", "Ständchen", "An die Nachtigall", "Feldeneisamkeit",
                  "Dein Blaues Augen", "Sonntag", "Immer leiser wird mein Schlummer", "Botschaft", "Regenlied", "Von evieger Liebe", 
                  "Ihs seid die Allerschönste", "Die Mainacht","Von ewiger Leben"]
      },
      {composer: "Britten",
         titles: ["Funeral Blues", "Johnny", "Tell me the truth about Love", "Serenade", "Calypso", "Canticle III", "Canticle IV", "Beware! (Three Early Songs)",
                  "Tit for Tat (Five Settings from Boyhood)", "Folk Song Arrangements from the British Isles Vol I , II, and III"]
      },
      {composer: "Britten/Purcell",
         titles: ["Evening Hymn", "Job's Curse"]
      },
      {composer: "Burleigh", 
         titles: ["Every Time I Feel the Spirit", "Deep River", "By and By", "Ride on Kind Jesus"]
      },
      {composer: "Caba",
         titles: ["Kapuri", "Kori Lilla"]
      },
      {composer: "Canteloube",
         titles: ["Bailrèro", "Tè L'co, Tè!", "Chut, Chut", "Le delaissádo", "Malurous qu'o uno fenno"]
      },
      {composer: "Carpenter",
         titles: ["When I bring to you colour'd toys" ]
      },
      {composer: "Charles",
         titles: ["Let My Song Fill Your Heart", "Clouds", "When I Have Sung My Songs", "The White Swan", "Night"]
      },
      {composer: "Chausson",
         titles: ["Chanson Perpétuelle, Op. 37", "Le Charme", "Les Papillons", "Le Colibri", "Cantique a l'Epouse"]
      },
      {composer: "Debussy",
         titles: ["En sourdine (Fêtes galantes)", "Fantoches (Fêtes galantes)", "Clair de lune (Fêtes falantes)", "C' est l' extase", "Chevaux de bois",
                  "Beau Soir", "Mandoline"]
      },
      {composer: "Delibes", 
         titles: ["Les Filles de Cadix"]
      },
      {composer: "Donaudy",
         titles: ["Vaghissima sembianza...", "Ognum ripicchia e nicchia...", "O del mio amato ben..", "Amor mi fa catare..."]
      },
      {composer: "Dougherty",
         titles: ["Love in the Dictionary"]
      },
      {composer: "Drofnatzki",
         titles: ["Gogdichtung"]
      },
      {composer: "Duke",
         titles: ["I can't be talkin' of love", "Loveliest of Trees", "When I was One and Twenty", "I carry your heart"]
      },
      {composer: "Duparc",
         titles: ["Chanson Triste", "Soupir", "L' invitation au voyage", "Phidylé", "Extase", "Lamento"]
      },
      {composer: "Fauré",
         titles: ["Cinq melodies de Venise", "La bonne chanson", "Apre un reve", "C' est l'extase", "En sourdine", "Chanson d'amour", "Ici-bas",
                  "Notre amour", "Lydia", "Sylvie", "Au bord de l'eau", "Automne", "Le berceaux", "Clair de lune", "Spleen"]
      },
      {composer: "L. C. Figueroa",
         titles: ["Berceuse"]
      },
      {composer: "N. Figueroa",
         titles: ["Madrugada", "Muerta", "mi rancho", "Vida criolla"]
      },
      {composer: "Finzi",
         titles: ["Let Us Garlands Bring", "Come away, come away, death", "Who is Silvia?", "Fear no more the heat o' the sun", "O mistress mine",
                  "It was a lover and his lass"]
      },
      {composer: "Guastavino",
         titles: ["El labrador y el pobre", "Balada", "Abismo de sed", "La rosa y el sauce", "Se equivocó la paloma", "Pampamapa"]
      },
      {composer: "Gershwin",
         titles: ["Our Love is here to stay", "Summertime", "Somebody Loves Me", "The Man I love"]
      },
      {composer: "Ginastera",
         titles: ["Cinco canciones populares argentinas", "Chacarera", "Triste", "Zamba", "Arroró", "Gato"]
      },
      {composer: "Gordon",
         titles: ["Luck", "My People", "Port Town", "Joy", "In Time of Silver Rain", "Will There Really Be a Morning"]
      },
      {composer: "Granados",
         titles: ["El majo discreto", "El majo timido"]
      },
      {composer: "Hahn",
         titles: ["Trois jour de vendange", "Si mes vers", "L' heure exquise", "Offrande", "Paysage triste"]
      },
      {composer: "Handel",
         titles: ["Rejoice greatly, O daughter of Zion", "I know that my redeemer liveth", "The trumpet shall sound", "Every valley shall be exhaulted", "But who may abide the day of his coming?"]
      },
      {composer: "Hoiby",
         titles: ["Bermudas (Duet)", "Songs for Leontyne", "Two Songs of Innocense", "Winter Song"]
      },
      {composer: "Hue",
         titles: ["A des oiseaux"]
      },
      {composer: "Hupton",
         titles: ["Canciones de Caribe", "Mama dice", "Lo piropos", "El mar tropical", "Deme un beso", "Las lágrimas"]
      },
      {composer: "Ibert",
         titles: ["Chanson du départ de Don Quichotte", "Chanson á Dulcinée", "Chanson du Duc"]
      },
      {composer: "Illanes",
         titles: ["Brisas del lago"]
      },
      {composer: "Ives",
         titles: ["Im Feldeneisamkeit", "The Circus Band"]
      },
      {composer: "Jones",
         titles: ["The Fourth Booke of Wyres"]
      },
      {composer: "Liszt",
         titles: ["Die Loreley", "Kling leise, mein Lied", "Du bist wie eine Blumme"]
      },
      {composer: "Mahler",
         titles: ["Fünf Lieder (Rückert", "Die Kindertotenlieder Song Cycle)", "Ablösung im Sommer", "Des Antonius von Padua Fischgepredigt", "Ich ging mit Lust durch einen grünen Wald", "Scheinen und Meiden",
                  "Um schlimme Kinder artig zu machen", "Um schlimme Kinder artig zu machen", "Wenn dein Mutterlein"]
      },
      {composer: "Marx",
         titles: ["Frage und Antwort", "Der bescheidene Schäfer", "Hat dich die Liebe beruht","Maienblüten", "Der Gefangene"]
      },
      {composer: "Maúrtua",
         titles: ["La Maria Angola", "A qué pueblo y por qué camino?"]
      },
      {composer: "Montsalvage",
         titles: ["Cinco Canciones Negras", "Cuba dentro de un piano", "Punto de habanera", "Chévere", "Canció de cuba", "Canto negro"]
      },
      {composer: "Mozart",
         titles: ["Ch' io mi scordi di te? Non amar amato bene (Concert Aria)", "Exsultate Jubilate", "Alleluia", "Laudamos te (Mass in C minor", "Et incarnatus est (Mass in C minor)", "An Chlöe", "Abendempfindung an Laura",
                  "Als Luise", "Das Veichen", "Ridente la calma", "Der Zauberer", "Rühe mein sanft"]
      },
      {composer: "Musto",
         titles: ["Shadow of the Blues", "Silhouette", "Litany", "Island", "Could be", "Plaza", "Cuando el caballo se para"]
      },
      {composer: "Poulenc",
         titles: ["Le Travail du Peintre", "La courte paille", "Miroirs brlüants", "Banalities", "Le portrait", "C.", "Violon", "Le chemins d' amour", "Tu vois le feyu de soir"]
      },
      {composer: "Previn",
         titles: ["Three Dickinson Songs", "As imperceptibly as grief", "Will there really be a morning?", "Good Morning--Midnight"]
      },
      {composer: "Purcell",
         titles: ["Sound the Trumpet", "If Music be the Food of Love", "Blow ye the Trumpet"]
      },
      {composer: "Quilter",
         titles: ["Seven Elizabethan Lyrics", "Weep you no more", "My life's delight", "Damsk roses", "The faithless shepherdess", "Brown is my love", "By a fountain inside"]
      },
      {composer: "Ravel",
         titles: ["Don Quichotte à Dulcinée", "Chanson Romanesque", "Chanson épique", "Chanson à boire", "Cinq Melodies populaires grecque", "Chanson de la mariée", "Là-bas ver l'église", "Quel Galant m'est comparable",
                  "Chanson de cueillesuses de lentisques", "Tous gai!", "Deux Melodies hebraiques", "Kadidish", "L'énigme étternelle"]
      },
      {composer: "Rachmaninoff",
         titles: ["Vocalise", "Rorem", "Early in the Morning", "Jeanie with the Light Brown Hair"]
      },
      {composer: "Rodrigo",
         titles: ["De los  álamos vengo, madre"]
      },
      {composer: "Rossini",
         titles: ["Duetto Buffo di Due Gattii", "La regata veneziana", "Sás", "Triolet", "La fuente"]
      },
      {composer: "Scarlatti",
         titles: ["Aria Si suoni la tromba"]
      },
      {composer: "Schubert",
         titles: ["Winterreise (Song Cycle)", "Schwanengesang (Song Cycle)", "The Shepherd on the Rock", "Die Taubenpost", "Heidenröslein", "Gretschen am Spinnerade", "Seufze", "Die gefangenen",
                  "Das Abendrot", "Punschlied (Duet)", "Nachtviolen", "Lilla an die Morgenrote", "Die Forelle", "Suleika I", "An die Musik", "Du bist die Ruh", "Lachen und Weinen"]
      },
      {composer: "Schumann",
         titles: ["Dichterliebe (Song Cycle)", "Liederkreis (Song Cycle)", "Mignon Lieder (Song Cycle)", "Der arme Peter (Song Cycle)", "Frauen Liebe und Leben (Song Cycle)", "Stille Frage", "Frage",
                  "Stille Tränen", "Widmung", "Der Nussbaum", "Lied der Suleika", "Die Lotosblumen", "Du bist wie eine Blume", "Keenst du das Land?", "Zum Schluss", "Wer sich der Einsamkeit ergibt"]
      },
      {composer: "Spohr",
         titles: ["Sei still mein Herz", "Zwiegesang", "Sehnsucht", "Wiegenlied in drei Tnöen", "Das heimliche Lied", "Wach auf"]
      },
      {composer: "Strauss",
         titles: ["Fünf Lieder", "Sehnsucht", "Liebeshymn", "O süsser Mai", "Himmelsboten", "Heimliche Aufforderung", "Die Nacht", "Nichts", "Heimkehr", "Morgen", "Zueignung", "Cäcilie", "Ständchen",
                  "Wie solltem wir geheim sie halten", "Allerselen"]
      },
      {composer: "Stravinsky",
         titles: ["The Owl and the Puzzy Cat"]
      },
      {composer: "Vivaldi",
         titles: ["Col piacer della mia fede", "Vedro con mio diletto", "Altra aria del vagante"]
      },
      {composer: "Walton",
         titles: ["Anon. in Love", "Fain would I change that note", "O stay, sweet love", "Lady, when I behold the roses", "My love in her attire", "I gave her cakes and I gave her ale", "To couple is a custom"]
      },
      {composer: "Weill",
         titles: ["Je ne t'aime pas", "Youkail", "Nana's Lied", "Saga of Jenny"]
      },
      {composer: "Williams",
         titles: ["Songs of Travel (Song Cycle)", "On Wenlock Edge (Song Cycle)", "Orpheus with His Lute"]
      },
      {composer: "Wolf",
         titles: ["Three Michelangelo Lieder (Song Cycle)", "Vier Gedichte nach Heine, Shakespeare und Lord Byron", "Sechs Lieder für eine Frauenstimme (Song Cycle)", "Italian Song Book:", "Auch kleine Dinge...", "Ihr seid die Allerschönste...", 
                  "Gesegnet sei, durch den die Welt entstund...", "Wer rief dich denn, wer hat dich herbestellt?", "Der Mond hat eine schwere Klag' erhoben...", "Nun lass uns Frieden schliessen...", "Mein Liebster ist so kein...", 
                  "Und willst du dein Liebsten sterben sehn...", "Mein Liebster singt am Haus im Mondenschein...", "Mein Liebster hat zu Tisch mich geladen...", "Lass sie nur gehn, die so die Stolze spielt...", "O wär dein Haus durchsichtig wie ein Glas...", 
                  "Du denkst mit einem Fädchen...", "Schweig einmal still...", "Spanish Song Book:", "Die ihr schwebt um diesem Palmen...", "In dem Schatten meiner Locken...", "Alle gingen, Herz, zur Ruh...", "Für mich, Kind, nach Bethlehem...", 
                  "Schmerzliche Wonne und wonnige Schmerzen...", "Bedecket mich mit Blumen...", "Geh, Geliebter, geh jetzt!"]
      },
      {composer: "Zamudio",
         titles: ["Para ti", "Zarzuelas", "Jugar con fuego", "La taberna del puerto", "Cancion del roussinol", "De Espaṅa vengo", "La carta", "Un tiempo fue", "Carceleras", "En un pais de fabula", "Cancion del Ruisenor", "Ese panuelito blanco", "Calate Corazón",
                  "Bella Enamorada"]
      },
      {composer: "Songs from:",
         titles: ["Latin America, Brazil, Costa Rica, and other countries"]
      }
   ]
}
