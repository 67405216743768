import React from 'react'

export default function AboutTab() {
    return (
        <div className="side-by-side">
            <div className="body-style">
                <div className="style4">
                    <p className="bio">Brazilian Pianist Rúbia Santos is among the most sought-after pianists from her home country. </p>
            <p className="quote">
                “Ms. Santos has a provocative pianism and an enthusiastic temperament....&quot;<br/>
            </p>

            <p className="quote" style={{marginTop: "-10px"}}>(Frankfurter Algemeine Zeitung)</p>

            <p className="bio">Santos’s performances include the standard classical repertoire, yet she is devoted
            to presenting and premiering works by composers of the Americas, and more
            strongly from her native country. This passion has led her to the forefront of
            performance and research of Brazilian music. Currently she is on the faculty at the University of Costa Rica, 
            and has previously taught at universities in the US and Germany. </p>

            <p className="bio">In 2013, Santos made the first editorial work of Eight Art Songs and Eight Piano
            Pieces by Brazilian contemporary composer, Edmundo Villani-Côrtes (Ponteio
            Publishing Inc., New York). Among her first releases are the Sonatas for Viola and
            Piano by Paul Chihara and E. Villani-Côrtes, and Trumpet Songs by 2010 Pulitzer
            prize winner composer Jennifer Higdon. Her recordings include “Chamber
            Music of Jennifer Higdon&quot; (Albany Records), “Moyugba Orisha” (White Pine
            Music Label), “A Brazilian Collection” (Tijuca Music), Concurso Nacional de
            Música de Câmara – São Paulo (CDA Label), and Música de Câmara by Lina Pires
            de Campos (Régia Música Label).</p>
            
            <p className="bio">Santos has joined as a collaborator with various artists and ensembles and has
            performed as a guest artist in music festivals, such as the opera program AIMS
            (Austria), Abiquiu Chamber Music Festival (Santa Fe), Festival Internacional de
            Campos do Jordão (Brazil), Festival Internacional Bach (Peru), and, in Costa Rica, 
            the XVIII Festival Internacional de Música Barroca (Costa Rica), X Festival 
            Internacional de Música de Cámara en Ciudad Colon, Festival de Música BAC Credomatic
            and I Festival Internacional de las Artes Unidas.</p>

            <p className="bio">Santos co-founded the Duo Brazil Brilliance with Brazilian violinist
            Cármelo de los Santos and the Duo Braziliana with American mezzo-soprano
            Melanie Ohm. Her love for poetry and knowledge of languages has taken her into
            various collaborations with singers, among them Christiane Oelze, Deborah
            Domanski, Sarah Wolfson, David Dillard, Javier Gonzales, Laura de Souza and
            Luciano Simões.  </p>
            
            <p className="bio">For many years, Santos has collaborated with members of the
            New York, LA, and Berlin Philharmonic, Orquestra Sinfónica de São Paulo, as
            well as with members from the Phoenix, Colorado, and Baltimore Symphonies.
            Santos has received many awards as pianist and teacher. She has adjudicated and
            presented at MTNA, the College Music Society, The Gilmore Keyboard Festival,
            the Mediterranean Studies Association and II Concurso Piano Latino Americano (Costa Rica).
            Santos was the guest pianist at the 6° Concurso Internacional de Piano (Panama), 
            accompanying the finalists with the piano concertos. 
            </p>

            <p className="bio">After her piano studies with pianist and composer Lina Pires de Campos in Brazil,
            Santos moved to Germany where she earned her artist diploma in piano solo at the
            Hochschüle für Musik in Cologne, mentored by Günter Ludwig (solo piano),
            Hartmut Höll (lLedbegleitung), and the Amadeus Quartet (chamber music). She
            holds a masters and a doctor of musical arts degree in collaborative piano from
            Arizona State University, having studied with Eckart Selheim (collaborative
            piano), William Reber (opera), and Caio Pagano (piano).</p>
            <p className="bio">Since 2015 Santos has been a Guild Certified Feldenkrais Practitioner,
            emphasizing the practice of the Method for musicians to enhance performance and
            avoid injuries.</p>
                </div>
            </div>
            <div className="body-style">
            <div className="style4">
                <p className="quote">“Santos tiene un pianismo provocador y un temperamento entusiasta ...&quot;</p>
                <p className="quote">(Frankfurter Zeitung Algemeine)</p>

                <p className="bio">La pianista brasileña Rúbia Santos es una de las pianistas más solicitadas en
                su país de origen. Actualmente es profesora de la cátedra de piano de la
                Universidad de Costa Rica y anteriormente ha enseñado en universidades
                de los Estados Unidos y Alemania.</p>

                <p className="bio">Sus actuaciones incluyen el repertorio clásico, sin embargo, ha dedicado a
                presentar y estrenar obras de compositores de las Américas y, más
                fuertemente, de su país natal. Esta pasión le ha llevado a la vanguardia de
                la interpretación y la investigación de la música brasileña. En 2013, publicó
                en los Estados Unidos la primera edición revisada de Ocho piezas para
                piano solo y Ocho canciones del compositor brasileño contemporáneo
                Edmundo Villani-Côrtes.</p>

                <p className="bio">Ha grabado en primer lanzamiento de “Moyugba Orisha” con las Sonatas
                para viola y piano de Paul Chihara y E. Villani-Côrtes (White Pine Label); la
                “Colección de Brasil” para trompeta y piano (Tijuca Music); Trumpet Songs
                de la compositora Jennifer Higdon, ganadora del 2010 Premio Pultizer
                (Albany Records) y La Música de Cámara de Lina Pires de Campos (Régia
                Música Label), entre otros.</p>
                Ha colaborado con varios artistas y ensambles, entre ellos miembros de la
                Filarmónica de Nueva York, Los Ángeles y Berlín, la Orquesta Sinfónica del
                Estado de São Paulo, así como con miembros de las Orquesta Sinfónicas de
                Phoenix, Colorado y Baltimore.

                <p className="bio">Ha actuado como artista invitada en festivales de música, como el
                programa de ópera AIMS (Austria), Abiquiu Chamber Music Festival (Santa
                Fé), Festival Internacional de Campos do Jordão (Brasil) y Festival
                Internacional Bach (Perú) entre otros. Y en Costa Rica,  en lo XVIII
                Festival Internacional de Música Barroca (2017, 2018), Encuentro de Pianistas -
                CR (2019,2021), X Festival Internacional de Música de Cámara Cuidad Colon (2018), 
                Festival de Música BAC Credomatic (2019), y en el Primero Festival Internacional
                de las Artes Unidas (2022).</p>

                <p className="bio">Es la con-fundadora del Dúo Brazil Brilliance con el violinista Cármelo de los
                Santos y Dúo Braziliana con la mezzosoprano americana Melanie Ohm. Su
                amor por la poesía y el conocimiento de las lenguas la ha llevado a
                colaborar con destacados cantantes, entre ellos Christiane Oelze, Deborah
                Domanski, Sarah Wolfson, David Dillard, Javier Gonzales, Laura de Souza y
                Luciano Simões.</p>

                <p className="bio">Ha sido ganadora de numerosos concursos como pianista y profesora. Ha
                presentado y actuado como miembro del jurado en MTNA, College Music
                Society, The Gilmore Keyboard Festival y Mediterranean Studies
                Association y en el II Concurso Nacional Piano Latino Americano.
                En 2014 fue la pianista invitada de 6° consurso Internacional de Piano en Panamá acompañando
                los finalistas que ejecutaran los concertos con orchestra.</p>

                <p className="bio">Después de sus estudios de piano con Lina Pires de Campos en Brasil, 
                se mudó a Alemania donde obtuvo su Diploma Artístico en piano solo en la
                Hochschüle für Music en Colonia, bajo la tutela de Günter Ludwig, (piano
                solo), Hartmut Höll (acompañamiento vocal) y el Cuarteto Amadeus (música
                de cámara). Tiene su maestría y doctorado en “Piano Colaborativo” de la
                Universidad Estadual de Arizona, habiendo estudiado con Eckart Sellheim,
                William Reber (opera) y Caio Pagano (piano solo).</p>
                
                <p className="bio">Desde 2015 es instructora certificada del Método Feldenkrais, actuando
                como instructora en la comunidad en Costa Rica, especialmente con
                músicos para perfeccionar la ejecución y evitar lesiones relacionadas a la
                práctica musical.</p>
            </div>
        </div>
        </div>
    )
}