import React from 'react';
import parse from "html-react-parser";

export default function Track({ trackIdx, trackInfo, setTracksShowing, setCurTrack, loadFn}) {

  const {name, performers} = trackInfo
  
  return (<div className="track" onClick={() => { setCurTrack(trackInfo); loadFn(); setTracksShowing(false) }}>
      <span style={{marginRight: "10px"}}>{trackIdx+1}. {parse(name)}</span>
      <span>{performers}</span>
    </div>
  )
}